import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

let currentModal;
let currentTrigger;
let currentScrollPosition = null;

function Modal() {
  const modals = Array.from(document.querySelectorAll('.c-modal'));
  modals.forEach(setupModal);
  
  window.addEventListener('keydown', e => onKeydown(e, modals));
}

// Function to clear video consent from localStorage (for testing or user preference)
function clearVideoConsent(videoId = null) {
  if (videoId) {
    // Clear consent for a specific video
    localStorage.removeItem(`video_consent_${videoId}`);
  } else {
    // Clear all video consents
    const keys = Object.keys(localStorage);
    keys.forEach(key => {
      if (key.startsWith('video_consent_')) {
        localStorage.removeItem(key);
      }
    });
  }
}

// Function to check if the user has already given consent for a specific video
function hasVideoConsent(videoId) {
  const localStorageKey = videoId ? `video_consent_${videoId}` : 'video_consent_general';
  return localStorage.getItem(localStorageKey) === 'true';
}

function setupModal(modal) {
  const id = modal.getAttribute('id');
  const triggers = document.querySelectorAll('[href="#' + id + '"]');

  Array.from(triggers).forEach(trigger => setupModalTrigger(trigger, modal));
  Array.from(modal.querySelectorAll('.c-modal__close,.c-modal__wing,.c-header')).forEach(close => setupCloseTrigger(close, modal, triggers[0]));
}

function setupCloseTrigger(close, modal, trigger) {
  close.addEventListener('click', (e) => {
    e.preventDefault();
    closeModal(modal, trigger);
  });
}

function onPopState(e) {
  e.preventDefault();
  closeModal(currentModal, currentTrigger);
}

function setupModalTrigger(trigger, modal) {
  trigger.addEventListener('click', (e) => {
    e.preventDefault();
    modal.classList.add('c-modal--open');
    modal.setAttribute('tabIndex', '-1');

    currentScrollPosition = window.scrollY;
    
    disableBodyScroll(modal);

    currentModal = modal;
    currentTrigger = trigger;
    history.pushState(null, document.title, location.href);
    window.addEventListener('popstate', onPopState);

    const video = modal.querySelector('iframe');
    if (video) {
      // Find the embed wrapper
      const embedWrapper = modal.querySelector('.c-embed__wrapper');
      // Get the cookie policy URL from the embed element
      const embedElement = modal.querySelector('.c-embed');
      const cookiePolicyUrl = embedElement ? embedElement.getAttribute('data-cookie-policy-url') : 'https://vimeo.com/cookie_policy';
      
      // Get video ID to use as a key for localStorage
      const videoId = video.getAttribute('data-src') ? 
        (video.getAttribute('data-src').match(/\/video\/(\d+)/) || [])[1] : 
        null;
      
      // Check if user has already given consent for this video
      const hasConsent = hasVideoConsent(videoId);
      
      if (video.getAttribute('src').includes('player.vimeo')) {
        const player = new Vimeo.Player(video);
        
        // Create and add the overlay for Vimeo videos if consent hasn't been given
        if (embedWrapper && !hasConsent) {
          // Create overlay if it doesn't exist
          if (!embedWrapper.querySelector('.c-embed__overlay')) {
            const overlay = document.createElement('div');
            overlay.className = 'c-embed__overlay';
            
            const message = document.createElement('p');
            // Create the message with a link for "Vimeo cookie policy"
            const messageText = document.createTextNode('Showing the video will request and load data from Vimeo (');
            const policyLink = document.createElement('a');
            policyLink.href = cookiePolicyUrl || 'https://vimeo.com/privacy/cookies';
            policyLink.textContent = 'Vimeo cookie policy';
            policyLink.target = '_blank';
            policyLink.className = 'u-link u-link--consent';
            policyLink.rel = 'noopener noreferrer';
            const messageTextEnd = document.createTextNode('), along with necessary cookies required for viewing.');
            
            message.appendChild(messageText);
            message.appendChild(policyLink);
            message.appendChild(messageTextEnd);
            
            const playButton = document.createElement('button');
            playButton.textContent = 'Watch the video';
            playButton.className = 'c-button u-link u-sans u-strong u-link-highlight c-button--shadow c-button--shadow-orange';
            
            playButton.addEventListener('click', () => {
              // Store consent in localStorage
              localStorage.setItem(videoId ? `video_consent_${videoId}` : 'video_consent_general', 'true');
              
              // Remove the overlay
              overlay.remove();
              // Play the video
              player.play();
            });
            
            overlay.appendChild(message);
            overlay.appendChild(playButton);
            embedWrapper.appendChild(overlay);
            
            // Prevent auto-play
            video.setAttribute('data-autoplay', '0');
          }
        } else if (hasConsent) {
          // If consent was already given, play the video automatically
          player.play();
        }
        
        // Don't auto-play the video if no consent
        //player.play();
      } else {
        video.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
      }
    }

    setTimeout(() => {
      const focusId = trigger.getAttribute('data-focus');
      const focusEl = document.getElementById(focusId);

      if (focusEl) {
        focusEl.setAttribute('tabIndex', '-1');
        focusEl.focus();
      } else {
        modal.focus();
      }
    }, 100);
  });
}

function onKeydown(e, modals) {
  if (e.key === 'Escape') {
    modals.forEach(modal => {
      if (modal.classList.contains('c-modal--open')) {
        const trigger = document.querySelector('[href="#' + modal.getAttribute('id') + '"]');
        closeModal(modal, trigger);
      }
    })
  }
}

function closeModal(modal, trigger) {
  modal.classList.remove('c-modal--open');
  modal.removeAttribute('tabIndex');
  document.documentElement.classList.add('no-smooth-scroll');

  const video = modal.querySelector('iframe');
  if (video) {
    // Remove any overlay if it exists
    const embedWrapper = modal.querySelector('.c-embed__wrapper');
    if (embedWrapper) {
      const overlay = embedWrapper.querySelector('.c-embed__overlay');
      if (overlay) {
        overlay.remove();
      }
    }

    if (video.getAttribute('src').includes('player.vimeo')) {
      const player = new Vimeo.Player(video);
      player.pause();
    } else {
      video.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
    }
  }

  window.removeEventListener('popstate', onPopState);

  trigger && trigger.focus();
  window.location.hash = trigger.id;
  setTimeout(() => {
    window.scrollTo(0, currentScrollPosition);
    history.replaceState(null, document.title, window.location.pathname);
    enableBodyScroll(modal);
  }, 1);


  setTimeout(() => {
    document.documentElement.classList.remove('no-smooth-scroll');
  }, 100);
}

export { clearVideoConsent };
export default Modal;
